<template>
  <ion-item :lines="lines" class="ion-no-padding" :router-link="`/reservations/${reservation.id}`">
    <ion-label>
      <div class="h6">
        {{ reservation.user.fullname }} - {{ reservation.confirmation }}
      </div>

      <div>
        {{ formatDate(reservation.arrival_date, "MMM d") }} -
        {{ formatDate(reservation.departure_date, "MMM d") }}
      </div>
    </ion-label>

    <!-- Right Side -->
    <ion-label slot="end" class="ion-text-right ion-no-margin">
      <!-- Reservation Status -->
      <ion-chip :color="reservationStatusColor">
        <ion-label class="ion-text-capitalize">{{
          reservation.status
        }}</ion-label>
      </ion-chip>

      <!-- Arrival Departure Status -->
      <ion-chip :color="arrivalDepartureStatus.color" v-if="arrivalDepartureStatus.title">
        <ion-label class="ion-text-capitalize">{{
          arrivalDepartureStatus.title
        }}</ion-label>
      </ion-chip>

      <!-- Checkin Status -->
      <ion-chip :color="checkinStatus.color">
        <ion-label class="ion-text-capitalize">{{
          checkinStatus.title
        }}</ion-label>
      </ion-chip>

      <div class="small">
        Room {{ reservation.room ? reservation.room.number : "not assigned" }}
      </div>
    </ion-label>
  </ion-item>
  <p v-if="showNotes">{{ reservation.general_notes }}</p>
</template>

<script>
import { IonItem, IonLabel, IonChip } from "@ionic/vue";
import { formatDate } from "@/util/helpers";
import { format } from "date-fns";

export default {
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
    showNotes: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IonItem,
    IonLabel,
    IonChip,
  },
  data() {
    return {
      formatDate,
    };
  },
  computed: {
    reservationStatusColor() {
      if (this.reservation.status === "hold") return "tertiary";
      else if (this.reservation.status === "confirmed") return "primary";
      else if (this.reservation.status === "cancelled") return "danger";

      return "tertiary";
    },

    checkinStatus() {
      if (this.reservation.checkout_date) return { color: "danger", title: "Checked-Out" };
      else if (this.reservation.checkin_date) return { color: "primary", title: "Checked-In" };

      return { color: "", title: "Not Checked-In" };
    },

    arrivalDepartureStatus() {
      let today = format(new Date(), "yyyy-MM-dd");
      let status = {
        color: "primary",
        title: null,
      };

      if (this.reservation.arrival_date) {
        // Arriving
        if (this.reservation.arrival_date === today)
          status = { color: "warning", title: "Arriving" };

        // Departing
        else if (this.reservation.departure_date === today)
          status = { color: "danger", title: "Departing" };

        // In House
        else if (this.reservation.checkin_date && !this.reservation.checkout_date)
          status = { color: "primary", title: "In-House" };
      }

      return status
    },
  },
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>