<template>
  <base-layout-one page-title="Reservations" content-class="background-white">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <section class="ion-padding-top ion-padding-start ion-padding-end">
      <ion-button class="regular-button" expand="block" router-link="/reservations/create">
        New Reservation
      </ion-button>
    </section>

    <!-- Search -->
    <section class="padding-x-8" v-if="false">
      <ion-searchbar placeholder="Search for Reservation" @click="showReservationSearchModal"></ion-searchbar>
    </section>

    <!-- Segments -->
    <section class="padding-x-16 padding-top-16">
      <ion-segment scrollable :value="segmentValue">
        <ion-segment-button value="in_house" @click="fetchReservationsSegment('in_house')">
          In-House
        </ion-segment-button>
        <ion-segment-button value="arriving" @click="fetchReservationsSegment('arriving')">
          Arriving
        </ion-segment-button>
        <ion-segment-button value="departing" @click="fetchReservationsSegment('departing')">
          Departing
        </ion-segment-button>
      </ion-segment>
    </section>

    <section class="padding-x-16 margin-top-8">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <!-- Left -->
          <ion-col size="12">
            <div class="ion-float-left" v-if="view === 'list'">
              <ion-button fill="solid" size="small" :color="view === 'list' ? 'primary' : 'medium'"
                @click="showReservationFilterModal">
                Filters ({{ filtersCount }})
              </ion-button>
            </div>

            <div class="ion-float-right">
              <!-- List View Button -->
              <ion-button fill="outline" size="small" :color="view === 'list' ? 'primary' : 'medium'"
                @click="view = 'list'">
                <ion-icon :icon="listOutline" size="small"> </ion-icon>
              </ion-button>

              <!-- Calendar View Button -->
              <ion-button fill="outline" size="small" :color="view === 'calendar' ? 'primary' : 'medium'"
                @click="view = 'calendar'">
                <ion-icon :icon="calendarOutline" size="small"> </ion-icon>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <!-- Reservations List -->
    <ion-list class="padding-x-16" v-if="view === 'list'">
      <reservation-list-item v-for="reservation in filteredReservations" v-bind:key="reservation.id"
        :reservation="reservation"></reservation-list-item>
    </ion-list>

    <!-- Reservations Calendar -->
    <section class="ion-padding" v-if="view === 'calendar' && fetchedCalendar === true">
      <full-calendar :options="calendarOptions" ref="fullCalendar"></full-calendar>
    </section>

    <ion-loading :is-open="showLoading" message="Loading Reservations" spinner="circles">
    </ion-loading>
  </base-layout-one>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonList,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  modalController,
  IonLoading,
} from "@ionic/vue";
import ReservationListItem from "@/components/reservations/ReservationListItem.vue";
import ReservationSearchModal from "@/components/reservations/ReservationSearchModal.vue";
import ReservationFilterModal from "@/components/reservations/ReservationFilterModal.vue";
import { listOutline, calendarOutline, filterCircleOutline } from "ionicons/icons";
import { userHasRole } from "@/util/helpers"
import { mapGetters } from "vuex";
import { format, subDays, addDays, isEqual, startOfMonth, endOfMonth, subMonths, addMonths } from "date-fns";

import FullCalendar from "@fullcalendar/vue3";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonButton,
    IonIcon,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    ReservationListItem,
    FullCalendar,
    IonLoading,
  },

  data() {
    return {
      listOutline,
      calendarOutline,
      filterCircleOutline,
      view: "list",
      segmentValue: "in_house",
      showLoading: false,
      fetchedCalendar: false,
      reservations: [],
      filteredReservations: [],
      properties: [],
      property: {},
      rooms: [],
      shift_counts: [],
      calendar_range_start: format(startOfMonth(subMonths(new Date(), 2)), 'yyyy-MM-dd'),
      calendar_range_end: format(startOfMonth(addMonths(new Date(), 2)), 'yyyy-MM-dd'),
      reservationEvent: {
        extendedProps: {
          user: {},
          package: {},
          property: {},
        },
      },
      filters: {
        cancelled: false,
        hold: true,
        confirmed: true,
        notCheckedIn: true,
        checkedIn: true,
        checkedOut: true,
        arrivalDate: null,
        departureDate: null,
      },
      calendarOptions: {
        schedulerLicenseKey: "0462219735-fcs-1641598496",
        plugins: [
          interactionPlugin, // needed for dateClick
          resourceTimelinePlugin,
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "", // "timeGridDay"
        },
        customButtons: {
          prev: { click: () => { this.prev() } },
          next: { click: () => { this.next() } },
          today: {
            text: "today",
            click: () => { this.today() }
          }
        },
        initialView: "resourceTimelineMonth",
        editable: false,
        eventDurationEditable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        slotLabelFormat: [{ weekday: "short", day: "numeric" }],
        resourceAreaWidth: "20%",
        resourceGroupField: "resource_group",
        resources: this.rooms,
        events: this.reservations,
        eventDrop: this.handleEventDrop,
        resourceOrder: "resource_group, title"
      },
    };
  },

  computed: {
    ...mapGetters(["globalProperty", "authUser"]),

    filtersCount() {
      return Object.values(this.filters).filter((x) => x).length;
    },
  },

  async ionViewDidEnter() {
    // Determine whether or not this calendar can be edited (reservations dragged) based on the user's role
    this.calendarOptions.editable = (userHasRole(this.authUser, "reservation_calendar_admin")) ? true : false

    // Fetch Reservations List View
    this.fetchReservationsSegment(this.segmentValue) // This also fetches the reservations for list view

    // Fetch Reservations Calendar View
    this.fetchReservationsCalendar();
  },

  methods: {
    /**
     * Refresh on pulldown
     * 
     * @param {*} event 
     */
    async refresh(event) {
      this.fetchReservationsSegment(this.segmentValue);
      this.fetchReservationsCalendar();

      event.target.complete();
    },

    /**
     * Fetch Reservations for a specific segment (in house, arriving, departing)
     * 
     * @param {String} segment 
     */
    fetchReservationsSegment(segment) {
      let today = format(new Date(), "yyyy-MM-dd");
      let yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");
      let tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");

      let params = {}

      if (segment === "in_house") {
        params = {
          cancelled: true,
          arrival_date_before: today,
          departure_date_after: today,
        };
      }
      else if (segment === "arriving") {
        params = {
          cancelled: true,
          arrival_date_before: tomorrow,
          arrival_date_after: yesterday,
        };
      }
      else if (segment === "departing") {
        params = {
          cancelled: true,
          departure_date_before: tomorrow,
          departure_date_after: yesterday,
        };
      }

      this.fetchReservations(params);
    },

    /**
     * Fetch Reservations
     * 
     * @param {*} params Params is an object with keys arrival_date_before, arrival_date_after, departure_date_before, departure_date_after
     */
    async fetchReservations(params) {
      // Add a Query String to customize reservations to fetch
      let queryString = "";
      if (params)
        queryString = Object.keys(params).map((key) => key + "=" + params[key]).join("&");

      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations?${queryString}`)
        .then((response) => {
          // Sort by Newest First
          this.reservations = response.data.reservations.sort((a, b) => { return new Date(b.id) - new Date(a.id) });

          // Filter based on chosen filters
          this.filterReservations();
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Reservations Calendar View
     */
    async fetchReservationsCalendar() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/reservationscalendar?property_id=${this.globalProperty.id}&arrival_date_after=${this.calendar_range_start}&departure_date_before=${this.calendar_range_end}`)
        .then((response) => {
          this.calendarOptions.resources = response.data.resources;
          this.calendarOptions.events = response.data.events;

          // Show the calendar
          this.fetchedCalendar = true

          // Hide loading
          this.showLoading = false;
        })
        .catch((error) => { console.log(error); this.showLoading = false });
    },


    /**
     * Fetch Shift Counts
     * 
     */
    async fetchShiftCounts() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/shifts/counts?range_start=${this.calendar_range_start}&range_end=${this.calendar_range_end}&`)
        .then((response) => { 
          // this.shift_counts = response.data.shift_counts;
          this.calendarOptions.events.concat(response.data.shift_counts)
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * FullCalendar - CalendarAPI
     */
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },

    /**
     * FullCalendar - Next in Calendar
     */
    async next() {
      this.showLoading = true;

      this.calendarApi().next();

      let date = this.calendarApi().getDate()
      this.calendar_range_start = format(startOfMonth(subMonths(date, 2)), 'yyyy-MM-dd')
      this.calendar_range_end = format(endOfMonth(addMonths(date, 2)), 'yyyy-MM-dd')

      this.fetchReservationsCalendar()
      this.fetchShiftCounts()
    },

    /**
     * FullCalendar - Prev in Calendar
     */
    prev() {
      this.showLoading = true;

      this.calendarApi().prev();

      let date = this.calendarApi().getDate()
      this.calendar_range_start = format(startOfMonth(subMonths(date, 2)), 'yyyy-MM-dd')
      this.calendar_range_end = format(endOfMonth(addMonths(date, 2)), 'yyyy-MM-dd')

      this.fetchReservationsCalendar()
      this.fetchShiftCounts()
    },

    /**
     * FullCalendar - Prev in Calendar
     */
    today() {
      this.showLoading = true;

      this.calendarApi().today();

      let date = this.calendarApi().getDate()
      this.calendar_range_start = format(startOfMonth(subMonths(date, 2)), 'yyyy-MM-dd')
      this.calendar_range_end = format(endOfMonth(addMonths(date, 2)), 'yyyy-MM-dd')

      this.fetchReservationsCalendar()
      this.fetchShiftCounts()
    },

    /**
     * FullCalendar - Event Click
     * 
     * @param {Object} event
     */
    handleEventClick({ event }) {
      this.reservationEvent = event;

      this.showReservationModal = true;

      this.$router.push(`/reservations/${event.id}`);
    },

    /**
     * FullCalendar - Event Dropped
     * Update the reservation if any of the following have changed: dates, room_id
     * 
     * @param {Object} event
     */
    async handleEventDrop({ oldEvent, event }) {
      let dateChanged = !isEqual(new Date(oldEvent.start), new Date(event.start))
      let resourceChanged = (oldEvent._def.resourceIds !== event._def.resourceIds) ? true : false

      let arrival_date = format(new Date(event.start), 'yyyy-MM-dd')
      let departure_date = format(subDays(new Date(event.end), 1), 'yyyy-MM-dd')
      let room_id = event._def.resourceIds[0]

      if (dateChanged || resourceChanged && userHasRole(this.authUser, "reservation_calendar_admin")) {
        const config = {
          method: "patch",
          url: `${process.env.VUE_APP_API}/reservations/${event.id}`,
          data: {
            arrival_date: arrival_date,
            departure_date: departure_date,
            room_id: room_id
          },
        };

        await this.axios(config)
          .then((response) => { if (response.data.reservation) this.fetchReservationsCalendar() })
          .catch((error) => { console.log(error) });
      }
    },

    /**
     * FullCalendar - Select a reservation
     * 
     * @param {*} reservation 
     */
    selectReservation(reservation) {
      this.showReservationSearchModal = false;
      this.$router.push(`/reservations/${reservation.id}`);
    },


    /**
     * Show the Reservation Filter Modal
     */
    async showReservationFilterModal() {
      const modal = await modalController.create({
        component: ReservationFilterModal,
        componentProps: {
          existingFilters: this.filters,
        }
      });

      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'save') {
        this.setFilters(data)
      }
    },

    /**
     * Set the filters
     * 
     * @param {Object} filters 
     */
    setFilters(filters) {
      this.filters = filters;
      this.filterReservations();
    },

    /**
     * Filter Reservations based on filters
     */
    filterReservations() {
      this.filteredReservations = this.reservations.filter((reservation) => {
        if (
          ((this.filters.cancelled && reservation.status === "cancelled") ||
            (this.filters.hold && reservation.status === "hold") ||
            (this.filters.confirmed && reservation.status === "confirmed"))

          &&

          ((this.filters.notCheckedIn && !reservation.checkin_date && !reservation.checkout_date) ||
            (this.filters.checkedIn && reservation.checkin_date && !reservation.checkout_date) ||
            (this.filters.checkedOut && reservation.checkout_date))
        )
          return true;

        return false;
      });
    },

    /**
     * Show the Reservation Search Modal
     */
    async showReservationSearchModal() {
      const modal = await modalController.create({
        component: ReservationSearchModal,
      });

      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'save') {
        this.setFilters(data)
      }
    }
  },
};
</script>

<style scoped>
.fc-timeline-event {
  text-align: center !important;
}
</style>