<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button :strong="true" @click="cancelModal">Cancel</ion-button>
        </ion-buttons>
        <ion-title>Reservation Search</ion-title>
        <ion-buttons slot="end"> </ion-buttons>
      </ion-toolbar>

      <ion-toolbar>
        <ion-searchbar v-model="searchTerm" debounce="500" :autofocus="true"></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list class="ion-padding-start ion-padding-end">
        <reservation-list-item v-for="reservation in reservations" v-bind:key="reservation.id"
          :reservation="reservation"></reservation-list-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonButtons,
  IonContent,
  IonList,
  modalController
} from "@ionic/vue";
import ReservationListItem from "@/components/reservations/ReservationListItem.vue"

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSearchbar,
    IonButtons,
    IonContent,
    IonList,
    ReservationListItem
  },

  data() {
    return {
      searchTerm: null,
      reservations: [],
      reservation: {},
    };
  },

  watch: {
    searchTerm() {
      this.fetchReservations(this.searchTerm);
    },
  },

  ionViewWillEnter() {
    // this.fetchReservations();
  },

  methods: {
    async fetchReservations(searchTerm) {
      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations${searchTerm ? `?first_name=${searchTerm}` : ""}`)
        .then((response) => { this.reservations = response.data.reservations; })
        .catch((error) => { console.log(error) });
    },

    selectReservation(reservation) {
      return modalController.dismiss(reservation, 'save');
    },

    /**
     * Close this Modal
     */
    cancelModal() {
      return modalController.dismiss(null, 'cancel');
    }
  },
};
</script>