<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button :strong="true" @click="cancelModal">Cancel</ion-button>
        </ion-buttons>
        <ion-title>Filter Reservations</ion-title>
        <ion-buttons slot="end">
          <ion-button :strong="true" @click="setFilters">Save</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="padding-y-16">
        <h4 class="ion-padding-start">Reservation Status</h4>
        <ion-list>
          <ion-item>
            <ion-checkbox slot="start" :checked="filters.cancelled" value="cancelled"
              @ionChange="handleCheck($event)"></ion-checkbox>
            <ion-label>Cancelled</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox slot="start" :checked="filters.hold" value="hold"
              @ionChange="handleCheck($event)"></ion-checkbox>
            <ion-label>Hold</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox slot="start" :checked="filters.confirmed" value="confirmed"
              @ionChange="handleCheck($event)"></ion-checkbox>
            <ion-label>Confirmed</ion-label>
          </ion-item>
        </ion-list>
      </div>

      <div class="padding-y-16">
        <h4 class="ion-padding-start">Check-In Status</h4>
        <ion-list>
          <ion-item>
            <ion-checkbox slot="start" :checked="filters.notCheckedIn" value="notCheckedIn"
              @ionChange="handleCheck($event)"></ion-checkbox>
            <ion-label>Not Checked-In</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox slot="start" :checked="filters.checkedIn" value="checkedIn"
              @ionChange="handleCheck($event)"></ion-checkbox>
            <ion-label>Checked-In</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox slot="start" :checked="filters.checkedOut" value="checkedOut"
              @ionChange="handleCheck($event)"></ion-checkbox>
            <ion-label>Checked-Out</ion-label>
          </ion-item>
        </ion-list>
      </div>

      <ion-button expand="block" fill="solid" class="padding-x-16 margin-bottom-24" @click="setFilters">Save</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
  modalController
} from "@ionic/vue";

export default {
  props: {
    existingFilters: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },

  data() {
    return {
      filters: {
        cancelled: false,
        hold: false,
        confirmed: false,
        checkedIn: false,
        checkedOut: false,
        notCheckedIn: false,
        arrivalDate: null,
        departureDate: null,
      },
    };
  },

  mounted() {
    this.filters = { ...this.existingFilters }
  },

  methods: {
    async handleCheck(event) {
      this.filters[event.detail.value] = event.detail.checked
    },

    /**
     * Save
     */
    setFilters() {
      return modalController.dismiss(this.filters, 'save');
    },

    /**
     * Close this Modal
     */
    cancelModal() {
      return modalController.dismiss(null, 'cancel');
    }
  },
};
</script>